import styled from "@emotion/styled";
import { toRadians } from "utils/math";
import { TOP_BAR } from "constants/z-index";
import Colors from "styles/colors";
import { CARD_BORDER_STYLE } from "segments/desktop/constants";
export const triangleHeightFactor = Math.sin(toRadians(8));
const CARD_MARGIN = "16px";
const CARD_BORDER_RADIUS = "40px";
const getCardWidthStyle = (cardWidth)=>`
				width: calc(min(100%, ${cardWidth}) - (${CARD_MARGIN} * 2));
				margin: 0 calc((100% - min(100%, ${cardWidth})) / 2 + ${CARD_MARGIN}) ;
`;
const cardBorderToStyle = {
    [CARD_BORDER_STYLE.FULL]: `border-radius: ${CARD_BORDER_RADIUS};`,
    [CARD_BORDER_STYLE.TOP]: `
    border-top-right-radius: ${CARD_BORDER_RADIUS};
    border-top-left-radius: ${CARD_BORDER_RADIUS};
  `,
    [CARD_BORDER_STYLE.BOTTOM]: `
    border-bottom-right-radius: ${CARD_BORDER_RADIUS};
    border-bottom-left-radius: ${CARD_BORDER_RADIUS};
  `,
    [CARD_BORDER_STYLE.NONE]: ""
};
export const StyledBaseLayoutSegmentComponent = styled.div`
  &.ease-in {
    opacity: 0;

    &.ease-in-animation {
      animation: fade-in 0.5s ease-in forwards;

      .segment-wrapper {
        animation: slide-in 0.8s ease-in;
      }
    }

    .segment-wrapper {
      transform: none;
    }
  }

  .segment-wrapper {
    transform: none;

    &.card {
      margin: 0 ${CARD_MARGIN};
      ${({ cardBorderStyle })=>cardBorderToStyle[cardBorderStyle || CARD_BORDER_STYLE.FULL]}
      background-color: ${({ cardBackgroundColor })=>Colors[cardBackgroundColor]};
      ${({ cardWidth })=>cardWidth ? getCardWidthStyle(cardWidth) : ""}
      padding: ${({ cardPadding })=>cardPadding};
    }
  }

  &.sticky {
    position: sticky;
    top: ${({ stickyTopOffset })=>stickyTopOffset || 0};
    z-index: ${TOP_BAR};
  }

  .triangle {
    position: absolute;
    z-index: 2;
    pointer-events: none;

    &.top {
      width: 0;
      height: 0;
      border-right: 100vw solid transparent;
      // the 0.05 addition solves a number rounding bug resulting in a 1px white line displayed
      margin-top: -${100 * triangleHeightFactor - 0.05}vw;
    }

    &.bottom {
      width: 0;
      height: 0;
      border-left: 100vw solid transparent;
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes slide-in {
    0% {
      transform: translateY(40px);
    }
    100% {
      transform: translateY(0px);
    }
  }
`;

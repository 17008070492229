import { WORK_MANAGEMENT_PRODUCT_ID, CRM_PRODUCT_ID } from "constants/products";
/* BADGES */ const usersLoveUs = {
    name: "Users love us",
    url: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/badges/users-love-us_1_1.png"
};
const leaderMidMarketSpring23 = {
    name: "Leader mid-market spring 2023",
    url: "https://dapulse-res.cloudinary.com/image/upload/v1689246883/remote_mondaycom_static/uploads/DafnaGlik/CRM%20LP%20assets/Individual%20badges/CRM_Leader_Mid-Market_Leader.png"
};
const leaderEnterpriseSpring23 = {
    name: "Leader enterprise spring 2023",
    url: "https://dapulse-res.cloudinary.com/image/upload/v1689246883/remote_mondaycom_static/uploads/DafnaGlik/CRM%20LP%20assets/Individual%20badges/EmailTracking_Leader_Enterprise_Leader.png"
};
const leaderSmallBusinessSpring23 = {
    name: "Leader small business spring 2023",
    url: "https://dapulse-res.cloudinary.com/image/upload/v1689246883/remote_mondaycom_static/uploads/DafnaGlik/CRM%20LP%20assets/Individual%20badges/SalesEnablement_Leader_Small-Business_Leader.png"
};
const leaderOfSpring23 = {
    name: "Leader spring 2023",
    url: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/DafnaGlik/CRM%20LP%20assets/Individual%20badges/EmailTracking_Leader_Leader.png"
};
const momentumLeaderSpring23 = {
    name: "Momentum leader spring 2023",
    url: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/DafnaGlik/CRM%20LP%20assets/Individual%20badges/SalesEnablement_MomentumLeader_Leader.png"
};
const top100FastestGrowingProduct = {
    name: "Top 100 fastest growing product",
    url: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/DafnaGlik/CRM%20LP%20assets/Individual%20badges/Frame_6.png"
};
const top100HighestSatisfactionProduct = {
    name: "Top 100 highest satisfaction product",
    url: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/DafnaGlik/CRM%20LP%20assets/Individual%20badges/Frame_5.png"
};
const top50SmallBusinessProduct = {
    name: "Top 50 small business product",
    url: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/DafnaGlik/CRM%20LP%20assets/Individual%20badges/Frame_3.png"
};
const top100SoftwareProduct = {
    name: "Top 100 software product",
    url: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/DafnaGlik/CRM%20LP%20assets/Individual%20badges/Frame_4.png"
};
const top50SalesProduct = {
    name: "Top 50 sales product",
    url: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/DafnaGlik/CRM%20LP%20assets/Individual%20badges/Frame_2.png"
};
const crmUsersLoveAll = {
    name: "Users love us",
    url: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/DafnaGlik/CRM%20LP%20assets/Individual%20badges/Group_2.png"
};
const bestUsabilitySmallBusinessSpring23 = {
    name: "Best usability small business spring 2023",
    url: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/DafnaGlik/CRM%20LP%20assets/best_usability_small_spring23.png"
};
const leaderAnzSpring23 = {
    name: "Leader ANZ spring 2023",
    url: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/DafnaGlik/CRM%20LP%20assets/Leader_ANZ_spring23.png"
};
const leaderAsiaPacificSpring23 = {
    name: "Leader Asia Pacific spring 2023",
    url: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/DafnaGlik/CRM%20LP%20assets/Leader_asaia_pasific_spring23.png"
};
const leaderAsiaSpring23 = {
    name: "Leader Asia spring 2023",
    url: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/DafnaGlik/CRM%20LP%20assets/Leader_Asia_spring23.png"
};
const highPerformerEnterpriseWinter2023 = {
    name: "High performer enterprise winter 2023",
    url: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/G2%20Badges/2023%20Winter/CRM_HighPerformer_Enterprise_HighPerformer.png"
};
const leaderEuropeWinter2023 = {
    name: "Leader Europe winter 2023",
    url: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/G2%20Badges/2023%20Winter/CRM_Leader_Europe_Leader.png"
};
const leaderMidMarketWinter2023 = {
    name: "Leader mid-market winter 2023",
    url: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/G2%20Badges/2023%20Winter/CRM_Leader_Mid-Market_Leader.png"
};
const leaderSmallBusinessWinter2023 = {
    name: "Leader small business winter 2023",
    url: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/G2%20Badges/2023%20Winter/CRM_Leader_Small-Business_Leader.png"
};
const momentumLeaderWinter2023 = {
    name: "Momentum leader winter 2023",
    url: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/G2%20Badges/2023%20Winter/CRM_MomentumLeader_Leader.png"
};
const bestUsabilitySmallBusinessWinter2023 = {
    name: "Best usability small business winter 2023",
    url: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/G2%20Badges/2023%20Winter/SalesEnablement_BestUsability_Small-Business_Total.png"
};
const leaderEnterpriseWinter2023 = {
    name: "Leader enterprise winter 2023",
    url: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/G2%20Badges/2023%20Winter/EmailTracking_Leader_Enterprise_Leader.png"
};
const leaderFall2024 = {
    name: "Leader fall 2024",
    url: "https://dapulse-res.cloudinary.com/image/upload/v1732182921/Generator_featured%20images/badges/Fall%202024/WorkManagement_Leader_Leader_1.png"
};
const leaderEnterpriseFall2024 = {
    name: "Leader enterprise fall 2024",
    url: "https://dapulse-res.cloudinary.com/image/upload/v1732182920/Generator_featured%20images/badges/Fall%202024/WorkManagement_Leader_Enterprise_Leader.png"
};
const usersMostLikelyToRecommendEnterpriseFall2024 = {
    name: "Users most likely to recommend enterprise fall 2024",
    url: "https://dapulse-res.cloudinary.com/image/upload/v1732182920/Generator_featured%20images/badges/Fall%202024/TaskManagement_UsersMostLikelyToRecommend_Enterprise_Nps_1.png"
};
const bestEstRoiEnterpriseFall2024 = {
    name: "Best estimated ROI enterprise fall 2024",
    url: "https://dapulse-res.cloudinary.com/image/upload/v1732182920/Generator_featured%20images/badges/Fall%202024/ProjectandPortfolioManagement_BestEstimatedROI_Enterprise_Roi_1.png"
};
const highestUserAdoptionEnterpriseFall2024 = {
    name: "Highest user adoption enterprise fall 2024",
    url: "https://dapulse-res.cloudinary.com/image/upload/v1732182920/Generator_featured%20images/badges/Fall%202024/EventPlanning_HighestUserAdoption_Enterprise_Adoption_1.png"
};
const fastestImplementationEnterpriseFall2024 = {
    name: "Fastest implementation enterprise fall 2024",
    url: "https://dapulse-res.cloudinary.com/image/upload/v1732182920/Generator_featured%20images/badges/Fall%202024/ProjectandPortfolioManagement_FastestImplementation_Enterprise_GoLiveTime_1.png"
};
const bestUsabilityFall2024 = {
    name: "Best usability fall 2024",
    url: "https://dapulse-res.cloudinary.com/image/upload/v1732182920/Generator_featured%20images/badges/Fall%202024/MarkupSoftware_BestUsability_Total_1.png"
};
const easiestAdminEnterpriseFall2024 = {
    name: "Easiest admin enterprise fall 2024",
    url: "https://dapulse-res.cloudinary.com/image/upload/v1732182920/Generator_featured%20images/badges/Fall%202024/BusinessInstantMessaging_EasiestAdmin_Enterprise_EaseOfAdmin_1.png"
};
/* BADGES PER PRODUCT PER COUNTRY */ const BADGES_WORK_MANAGEMENT_GENRAL = [
    highestUserAdoptionEnterpriseFall2024,
    usersMostLikelyToRecommendEnterpriseFall2024,
    bestUsabilityFall2024,
    leaderFall2024,
    usersLoveUs,
    leaderEnterpriseFall2024,
    easiestAdminEnterpriseFall2024,
    fastestImplementationEnterpriseFall2024,
    bestEstRoiEnterpriseFall2024
];
const BADGES_CRM_GENERAL = [
    leaderMidMarketSpring23,
    leaderEnterpriseSpring23,
    leaderSmallBusinessSpring23,
    leaderOfSpring23,
    momentumLeaderSpring23,
    crmUsersLoveAll,
    top100FastestGrowingProduct,
    top100HighestSatisfactionProduct,
    top50SmallBusinessProduct,
    top100SoftwareProduct,
    top50SalesProduct
];
const BADGES_CRM_JAPAN = [
    leaderMidMarketSpring23,
    leaderEnterpriseSpring23,
    leaderSmallBusinessSpring23,
    leaderOfSpring23,
    momentumLeaderSpring23,
    crmUsersLoveAll,
    top50SalesProduct,
    bestUsabilitySmallBusinessSpring23,
    leaderAnzSpring23,
    leaderAsiaPacificSpring23,
    leaderAsiaSpring23
];
const BADGES_CRM_FRANCE = [
    top50SalesProduct,
    top100SoftwareProduct,
    bestUsabilitySmallBusinessWinter2023,
    highPerformerEnterpriseWinter2023,
    top100HighestSatisfactionProduct,
    crmUsersLoveAll,
    leaderMidMarketWinter2023,
    leaderSmallBusinessWinter2023,
    momentumLeaderWinter2023,
    leaderEuropeWinter2023,
    leaderEnterpriseWinter2023
];
/* EXPORTS */ export const BADGES_COUNTRIES = {
    GENERAL: "General (default)",
    JAPAN: "Japan",
    FRANCE: "France"
};
export const BADGES_PER_PRODUCT_BY_COUNTRY = {
    [WORK_MANAGEMENT_PRODUCT_ID]: {
        [BADGES_COUNTRIES.GENERAL]: BADGES_WORK_MANAGEMENT_GENRAL
    },
    [CRM_PRODUCT_ID]: {
        [BADGES_COUNTRIES.GENERAL]: BADGES_CRM_GENERAL,
        [BADGES_COUNTRIES.JAPAN]: BADGES_CRM_JAPAN,
        [BADGES_COUNTRIES.FRANCE]: BADGES_CRM_FRANCE
    }
};
